import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Button from '../common/Button/Button';

import * as messages from '../../locales/en.json';

const AboutHeader = () => (
  <section className="about-header">
    <div className="container about-header-intro">
      <div className="row align-items-center">
        <div className="col-sm">
          <h1>{messages['about.header.title']}</h1>
        </div>
        <div className="col-sm">
          <p>{ReactHtmlParser(messages['about.header.desc'])}</p>
        </div>
      </div>
    </div>
    <div className="about-header-image-wrapper">
      <div className="container">
        <div className="about-header-card">
          <h2>{messages['about.card.title']}</h2>
          <p>{ReactHtmlParser(messages['about.card.desc'])}</p>
          <Button
            linkTo="/contact-us"
            buttonStyle="primary"
            size="medium"
            title={messages['about.card.button']}
          />
        </div>
      </div>
    </div>
  </section>
);

export default AboutHeader;
