import React from 'react';
import { object } from 'prop-types';

import * as messages from '../../locales/en.json';

const AboutPartners = ({ partners }) => (
  <section className="container about-partners">
    <h3>{messages['about.partners.title']}</h3>
    <div className="row align-items-center">
      {partners.nodes.map(({ name, src }, id) => (
        <div key={id} className="col-sm-6 col-md about-partners-member">
          <img width="190" height="190" src={src.childImageSharp.fluid.src} alt={name} />
        </div>
      ))}
    </div>
  </section>
);

AboutPartners.propTypes = {
  partners: object.isRequired,
};

export default AboutPartners;
